@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-yellow {
    @apply bg-primary_yellow hover:bg-primary_blue text-primary_font font-inter font-extrabold py-2 px-4 rounded-full;
  }
  .btn-white {
    @apply bg-transparent hover:bg-primary_blue text-primary_font font-inter font-normal py-2 px-4 rounded-full;
  }
  .animation-delay-2000 {
    animation-delay: 2s;
  }
  .animation-delay-4000 {
    animation-delay: 4s;
  }
  .text-field-label {
    @apply block mb-2 text-[16px] lg:text-[20px] font-inter font-medium text-black;
  }
  .text-field {
    @apply bg-gray-200 border border-gray-200 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder:text-black;
  }
  .timeline-line {
    @apply h-[200px] w-1 bg-primary_yellow;
  }
  .timeline-circle {
    @apply w-10 h-10 absolute top-1/4 -mt-3 rounded-full bg-primary_yellow shadow text-center;
  }
  .timeline-content {
    @apply bg-transparent col-start-4 col-end-12 pt-4 mt-3 rounded-xl mr-auto w-full hover:border border-gray-400 h-fit;
  }
  .timeline-heading {
    @apply font-inter font-bold text-white text-[24px] xs:text-[28px] sm:text-[36px] mb-1;
  }
  .timeline-subheading {
    @apply leading-tight text-justify text-white text-[18px] xs:text-[16px] sm:text-[20px] opacity-60 font-inter font-medium;
  }
}

* {
  scroll-behavior: smooth;
}

.sidebar {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.blue__gradient {
  border-radius: 100% 50% 70% 50%;
  background: linear-gradient(180deg, #3c68ff 0%, #3bc9fb 100%);
  filter: blur(100px);
  transform: rotate(-75deg);
}

.yellow__gradient {
  border-radius: 24% 36% 76% 50%/37% 56% 64% 73%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #fec846 0%,
    rgba(254, 200, 70, 0.5) 100%
  );
  filter: blur(50px);
  transform: rotate(40deg);
}

.main_heading {
  -webkit-text-stroke: 5px #011637;
  color: transparent;
}

.animate_robo {
  animation: up-down 1s ease-in-out infinite alternate-reverse both;
}
@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

.text-field {
}
